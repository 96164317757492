<div class="page-container" style="padding: 0px 10px 0px 10px;">
  <div class="full-width loading-panel animated" [class.show]="loadStatus=='loading'">
    <!--    <img class="loading-panel-829"  src="/assets/829.png" alt="Загрузка..."/>-->
    <img class="loading-panel-299" src="/assets/299.png" alt=""/>
  </div>
  <div class="full-width not-found-panel animated" [class.show]="loadStatus=='not-found'">
    <img class="loading-panel-sorry" src="/assets/sorry.png" alt=""/>
    <p style="margin: 20px 20px; alignment: center;">Что-то пошло не так. Вероятно вы попали на эту страницу с помощью
      неправильной ссылки. Но если вы уверены в своих действиях то обратитесь к администрации.</p>
  </div>
  <div class="full-width main-panel animated" [class.show]="loadStatus=='exists'">
<!--    <h1 style="font-weight: bold">Электронный бюллетень</h1>-->
    <h2 style="font-weight: bold">Электронный помощник для заполнения бюллетеня</h2>
    <h3 style="alignment: center">Решение собственника: <b>{{fio}}</b></h3>
    <div id="lock-mode" *ngIf="locked" style="background: #ffd400; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">
      <span *ngIf="locked==='finish'">Голосование завершено. <a  [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment('results', 'lock-mode')">Результаты голосования</a></span>
      <span *ngIf="locked!=='finish' && locked!=='finish-result'">
      Решение закрыто <span *ngIf="locked==='tenant'">собственником</span><span
        *ngIf="locked!=='tenant'">администрацией</span>. Редактирование невозможно.
      </span>
      <span *ngIf="locked==='finish-result'">
      Голосование завершено. Редактирование невозможно.
      </span>
    </div>
    <div id="begin">
      <mat-accordion >
        <mat-expansion-panel (afterCollapse)="scrollToFragment('begin', null);">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-ellipsis">
             <span><mat-icon style="vertical-align: sub; color: #ffd400;">info</mat-icon>Информация</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div style="font-weight: bold">
            <ul>
              <li>Это не электронное голосование, а помощник в принятии решения и заполнении бюллетеня.</li>
              <li>Попасть сюда можно только по уникальной ссылке для каждого собственника.</li>
              <li>Для принятия решений по вопросам повестки дня, в разделе «Приложения» загружены все необходимые документы.</li>
              <li>Вы можете поставить галочку «Показать вопросы подробно», для раскрытия официального текста повестки в таблице.</li>
              <li>Итоговый бюллетень должен быть распечатан самостоятельно или в правлении, подписан и сдан счетной комиссии, с копией доверенности на представителя, если голосует не собственник.</li>
              <li style="
              background: yellow;
              padding: 3px 6px;
              border-radius: 5px;
              ">Все подробности в самом низу в разделе Подробная информация.</li>
              <li>Результаты голосования после публикации можно будет увидеть в разделе "<a  [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment('results', 'start-info')">Результаты голосования</a>"</li>
            </ul>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel (afterCollapse)="scrollToFragment('begin', null);">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-ellipsis">
              <span><mat-icon style="vertical-align: sub; color: #00ffff;">self_improvement</mat-icon>Формальности</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="!delegateSelected && !locked">
            <h2 >Кем Вы являетесь по отношению к указанному выше собственнику? </h2>
            <button class="full-width" style ="width: 100%;" color="primary" mat-raised-button (click)="delegateSelected = true; isDelegate = false; doverka = ''; fillStatus=[]; saveDoverka();">Я и есть указанный собственник</button>
            <br>
            <br>
            <button class="full-width" style ="width: 100%;" color="primary" mat-raised-button (click)="delegateSelected = true; isDelegate = true; fillStatus=[]">Я представитель
              указанного собственника по доверенности
            </button>
            <br>
            <br>
            <br>
            <br>
          </div>
          <div *ngIf="delegateSelected || locked">
            <div *ngIf="isDelegate && locked">
              Заполнил представитель указанного собственника по доверенности: {{doverka}}
            </div>
            <div *ngIf="isDelegate && !locked">
              Заполняет представитель указанного собственника по доверенности: {{doverka}}
            </div>
            <div *ngIf="!isDelegate && locked">
              Заполнил титульный собственник
            </div>
            <div *ngIf="!isDelegate && !locked">
              Заполняет титульный собственник
            </div>
          </div >
          <h2 *ngIf="isDelegate && delegateSelected && delegateEntered" style="alignment: center">Голосует представитель собственника: <b>{{doverka}}</b></h2>
          <h2 *ngIf="!isDelegate && delegateSelected" style="alignment: center">Голосует собственник</h2>


          <div *ngIf="isDelegate && delegateSelected && !delegateEntered">
            <div style="font-weight: normal;" class="full-width">Если вы представитель собственника введите в поле ниже
              сведения: свои ФИО
              полностью, а также номер доверенности. Данные пригодятся для автоматического заполнения полей решения:
            </div>
            <div style="">
              <mat-form-field style="width: 100%; margin-top: -13px; margin-bottom: 5px;" class="full-width">
                <input matInput [(ngModel)]="doverka" name="doverka" rows="1" >
                <mat-hint> (Ф.И.О. представителя и сведения о документе, подтверждающем его
                  полномочия)
                </mat-hint>
              </mat-form-field>
              <!--        <div><span>Продолжая, Вы даете согласие ТСН "Вернисаж" согласие на обработку следующих своих персональных данных, а именно:<br> - фамилия, имя, отчество; <br>на автоматизированную, а также без использования средств автоматизации обработку, а именно на сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</span></div>-->
            </div>
            <br>
            <br>
            <button mat-raised-button color="primary"  class="full-width"  style ="width: 100%;" (click)="saveDoverka(); delegateEntered=true" [disabled]="!delegateValid">Поле представителя заполнено. Давайте дальше.</button>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (afterCollapse)="scrollToFragment('begin', null);">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-ellipsis">
              <span><mat-icon style="vertical-align: sub; color: #0022ff;">ballot</mat-icon>Бюллетень. Вопросы решения.</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div  *ngIf="!(locked || (delegateSelected &&  (!isDelegate || delegateEntered)))">
            <h2>Определите формальности</h2>
            <p>В разделе выше укажите, кем вы являетесь по отношению к собственнику.</p>
          </div>
          <div *ngIf="locked || (delegateSelected &&  (!isDelegate || delegateEntered))">
<!--            <mat-checkbox class="full-width" style="font-weight: bold" [(ngModel)]="verbose" name="verbose-qwsts">Показать-->
<!--              вопросы подробно-->
<!--            </mat-checkbox>-->
            <br>
            <table class="full-width" style="margin: 0px -22px -14px -22px;
    width: calc(100% + 44px); " border="1" cellpadding="5">
              <tr style="background: #d6d6d6;">
                <th style="">
                  №
                </th>
                <th>
                  Вопросы повестки дня
                  <hr>
                  Решение
                </th>
<!--                <th style="width: 50px;" colspan="3">-->
<!--                  Решение-->
<!--                </th>-->

              </tr>
              <ng-container *ngIf="bulletin && bulletin.qwsts">
                <tr *ngFor="let question of bulletin.qwsts" [className]="question.type">
                  <th style="vertical-align: top;"> {{question.number}}</th>
                  <td [colSpan]="question.type == 'header'?4:1">  <!-- назвние-->
                    <div [className]="question.type" >
                      <div style="font-weight: bold; font-size: 17px;"  [id]="'question'+question.id">{{question.title}}</div>
                      <div style="font-weight: bold; font-size: 12px;"><br>{{question.short_question}}</div>
                      <ng-container *ngIf="question.verbose">
                        <div *ngIf="question.type != 'sub-question'"><pre class="agenda-content"><br>ПОВЕСТКА: {{question.agenda}}</pre></div>
                        <div style="font-style: italic;"
                             *ngIf="question.type == 'sub-question' || question.type == 'question'">
                          <br><pre class="decision-content">РЕШЕНИЕ: {{question.decision}}</pre></div>
                        <a mat-button color="primary" *ngIf="question.verbose" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="question.verbose=false; scrollToFragment('question'+question.id, null)">Свернуть подробности...</a>
                      </ng-container>
                      <a mat-button color="primary" *ngIf="!question.verbose" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="question.verbose=true">Подробно...</a>
<!--                    <a  mat-button color="primary" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" [id]="'question'+question.id" (click)="scrollToFragment('appendix', 'question'+question.id)">Приложения...</a>-->
                    </div>
<!--                  </td>-->
                  <ng-container *ngIf="question.type != 'header'">

<!--                    <td colspan="4" style="padding: 0">-->
                    <hr>
                      <ng-container *ngIf="!question.answer">
                        <div style="display: flex;">
                        <button style="flex-grow: 1" class="answer-button set-answer-button color-for" mat-flat-button (click)="setAnswer(question.id, 'f')">ЗА
                        </button>
                        <button style="flex-grow: 1" class="answer-button set-answer-button color-against" mat-flat-button (click)="setAnswer(question.id, 'a')">ПРОТ
                        </button>
                        <button style="flex-grow: 1" class="answer-button set-answer-button color-abstained" mat-flat-button (click)="setAnswer(question.id, 'r')">
                          ВОЗД
                        </button>
                        </div>
                      </ng-container>

                      <button *ngIf="question.answer == 'f'" class="answer-button reset-answer-button color-for" mat-flat-button
                              (click)="setAnswer(question.id, '')">  ЗА<br/>
                        <span *ngIf="!locked" style="font-weight: normal; font-size: 10px;">нажмите для отмены</span>

                      </button>
                      <button *ngIf="question.answer == 'a'" class="answer-button reset-answer-button color-against"
                              mat-flat-button (click)="setAnswer(question.id, '')">ПРОТИВ<br/>
                        <span *ngIf="!locked" style="font-weight: normal; font-size: 10px;">нажмите для отмены</span>
                      </button>
                      <button *ngIf="question.answer == 'r'" class="answer-button reset-answer-button color-abstained"
                              mat-flat-button (click)="setAnswer(question.id, '')">ВОЗДЕРЖАЛСЯ<br/>
                        <span *ngIf="!locked" style="font-weight: normal; font-size: 10px;">нажмите для отмены</span>
                      </button>

                  </ng-container>
                  </td>

                </tr>
              </ng-container>
            </table>


          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (afterCollapse)="scrollToFragment('begin', null);" >
          <mat-expansion-panel-header>
            <mat-panel-title class="text-ellipsis">
              <span>
                <mat-icon *ngIf="!isGood && fillStatus && fillStatus.length>1" style="vertical-align: sub; color: #c10000;">sentiment_very_dissatisfied</mat-icon>
                <mat-icon *ngIf="!isGood && fillStatus && fillStatus.length==1" style="vertical-align: sub; color: #7c2c00;">sentiment_dissatisfied</mat-icon>
                <mat-icon *ngIf="!isGood && fillStatus && fillStatus.length==0" style="vertical-align: sub; color: #0099ff;">sentiment_satisfied</mat-icon>
                <mat-icon *ngIf="isGood && !locked" style="vertical-align: sub; color: #10ff00;">sentiment_very_satisfied</mat-icon>
                <mat-icon *ngIf="isGood && locked" style="vertical-align: sub; color: #10ff00;">thumb_up</mat-icon>
                Что не так и что дальше</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h2>Что не так:</h2>
          <mat-error *ngFor="let status of fillStatus" style="color: #c13227">
            <mat-icon color="accent" style="vertical-align: middle">warning</mat-icon>
            {{status}}
          </mat-error>
          <mat-hint *ngIf="isGood && !locked" style="color: green">
            <mat-icon style="vertical-align: middle">thumb_up</mat-icon>
            Все отлично! Можете нажать кнопку "Завершить"!
          </mat-hint>
          <mat-hint *ngIf="isGood && locked" style="color: green">
            <mat-icon style="vertical-align: middle">thumb_up</mat-icon>
            Все отлично! Вы проголосовали!
          </mat-hint>
          <ng-container *ngIf="!isGood">
            <div style="color: black " *ngIf="!locked" >
              <span><mat-icon style="vertical-align: bottom; color: gold;">warning</mat-icon>Ваш бюллетень еще не заполнен, но вы можете действовать согласно пунктам "Что же дальше".</span>
            </div>
            <div style="color: black " *ngIf="locked" >
              <span><mat-icon style="vertical-align: bottom; color: gold;">warning</mat-icon>Ваш бюллетень заблокирован.</span>
            </div>
          </ng-container>
          <ng-container  *ngIf="isGood">
            <div style="color: #d7a500 " *ngIf="!locked" >
              <span><mat-icon style="vertical-align: bottom">thumb_up</mat-icon>Ваше решение заполнено, осталось его закрыть, нажмите на кнопку "Завершить".</span>
            </div>
            <ng-container  *ngIf="locked">
              <div style="color: darkgreen" *ngIf="locked==='tenant'" >
                <span><mat-icon style="vertical-align: bottom">check_box</mat-icon>Ваше решение заполнено и закрыто! Можно действовать дальше согласно пунктам ниже.</span>
              </div>
              <div style="color: darkgreen" *ngIf="locked==='finish'" >
                <span><mat-icon style="vertical-align: bottom">check_box</mat-icon>Ваше решение подтверждено администрацией! Ожидайте окончания голосования.</span>
              </div>
              <div style="color: black" *ngIf="locked==='finish-result'" >
                <span><mat-icon style="vertical-align: bottom">flag</mat-icon>Голосование завершено! Внизу можно увидеть таблицу результатов.</span>
              </div>

            </ng-container>

          </ng-container>




          <h2>Что же дальше:</h2>
          <ul style="width: calc(100% - 40px);">
            <ng-container *ngIf="!locked || locked === 'tenant'">
            <li *ngIf="locked == 'tenant' && isDelegate" style="background: #ffd400; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">
              Не забудьте приложить копию доверенности, оформленную в соответствии с требованиями п.4,5 ст. 185 ГК РФ или
              удостоверенная нотариально
            </li>
            <li ><span>Можете сами распечатать <span *ngIf="locked">заполненное </span><span *ngIf="!locked">пустое </span> решение подписать его и отнести в правление</span></li>
            <li>Можете прийти в правление, где его вам распечатают и вы подпишите.</li>
            <li>Или распечатайте, подпишите и отправьте почтой РФ, если нет возможности принести лично,  по адресу:<br/> <u>188692, Россия, Ленинградская обл., Всеволожский р-н, г. Кудрово, ул. Пражская, д. 7, кв.319.</u></li>
            <li>Если вы на самоизоляции или у вас сложности с посещением правления, оставьте электронную заявку или позвоните в правление (в рабочее время по номеру: <a href="tel:+7(962)690-05-22">+7 (962) 690-05-22</a>) и к вам подойдет представитель счетной комиссии с/за Вашим распечатанным <span *ngIf="locked">и заполненным вами</span> бюллетенем.</li>
            </ng-container>
            <li *ngIf="locked && locked !== 'tenant' && locked !== 'finish' && locked !== 'finish-result' " >Ожидайте окончания голосования и публикации результатов.</li>
            <ng-container  *ngIf="locked === 'finish' || locked === 'finish-result'" >
              <li>Ожидайте окончания голосования и публикации результатов.</li>
            </ng-container>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel (afterCollapse)="scrollToFragment('begin', null); collapseItemPanel($event)">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-ellipsis">
              <span><mat-icon style="vertical-align: sub; color: #0022ff;">picture_as_pdf</mat-icon>Приложения</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <app-files ownerName="oss" [readOnly]="true" title="" [ownerGUId]="ossGuid" >
            </app-files>
            <p>По результатам голосования тут появятся общедоступные документы протокола голосования.</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>


<!--      <h2 id="start-info"><span><mat-icon style="vertical-align: sub; color: #ffd400;">info</mat-icon>Информация</span></h2>-->

    </div>

    <div *ngIf="!locked && delegateSelected">

<!--      <h2>Что не так:</h2>-->
<!--      <mat-error *ngFor="let status of fillStatus" style="color: #c13227">-->
<!--        <mat-icon color="accent" style="vertical-align: middle">warning</mat-icon>-->
<!--        {{status}}</mat-error>-->
<!--      <mat-hint *ngIf="isGood" style="color: green">-->
<!--        <mat-icon style="vertical-align: middle">thumb_up</mat-icon>-->
<!--        Все отлично! Можете нажать кнопку "Завершить"!-->
<!--      </mat-hint>-->
<!--      <br>-->
<!--      <br>-->

      <button class="full-width"  style ="width: 100%;" *ngIf="!isBeginFinish" mat-raised-button color="primary" (click)="isBeginFinish = true" [disabled]="!isGood">
        Завершить
      </button>
      <br>
      <br>
      <div *ngIf="isBeginFinish">
        <div>
          <mat-icon style="vertical-align: middle; color: #ffd400;">info</mat-icon>
          Вы уверены? Нажимая кнопку "Точно завершить", решение закроется и голос изменить будет нельзя. Зато вы
          сможете получить заполненное решение для печати.
        </div>
        <br>
        <div style="display: flex; flex-direction: row; width: 100%;" class="full-width">
          <button style="flex-grow: 2;" mat-raised-button color="primary"
                  (click)="isBeginFinish = false; lockBulletin()">Точно завершить!
          </button>
          <button style="flex-grow: 1;" mat-raised-button color="accent" (click)="isBeginFinish = false;">Нет, еще
            подумаю.
          </button>
        </div>
      </div>
      <br>
    </div>
<!--    <div style="color: black " *ngIf="!isGood && !locked" >-->
<!--      <span><mat-icon style="vertical-align: bottom; color: gold;">warning</mat-icon>Ваш бюллетень еще не заполнен, но вы можете действовать согласно пунктам ниже.</span>-->
<!--    </div>-->
<!--    <div style="color: #d7a500 " *ngIf="isGood && !locked" >-->
<!--      <span><mat-icon style="vertical-align: bottom">thumb_up</mat-icon>Ваше решение заполнено, осталось его закрыть, нажмите на кнопку "Завершить".</span>-->
<!--    </div>-->
<!--    <div style="color: darkgreen" *ngIf="isGood && locked" >-->
<!--      <span><mat-icon style="vertical-align: bottom">check_box</mat-icon>Ваше решение заполнено и закрыто! Можно действовать дальше согласно пунктам ниже.</span>-->
<!--    </div>-->
<!--    <h2>Что же дальше:</h2>-->
<!--     <ul style="    width: calc(100% - 40px);">-->
<!--       <li *ngIf="locked && isDelegate" style="background: #ffd400; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">-->
<!--         Не забудьте приложить копию доверенности, оформленную в соответствии с требованиями п.4,5 ст. 185 ГК РФ или-->
<!--         удостоверенная нотариально-->
<!--       </li>-->
<!--       <li><span>Можете сами распечатать <span *ngIf="locked">заполненное </span><span *ngIf="!locked">пустое </span> решение подписать его и отнести в правление</span></li>-->
<!--       <li>Можете прийти в правление, где его вам распечатают и вы подпишите.</li>-->
<!--       <li>Или распечатайте, подпишите и отправьте почтой РФ, если нет возможности принести лично,  по адресу:<br/> <u>188692, Россия, Ленинградская обл., Всеволожский р-н, г. Кудрово, ул. Пражская, д. 7, кв.319.</u></li>-->
<!--       <li>Если вы на самоизоляции или у вас сложности с посещением правления, оставьте электронную заявку или позвоните в правление (в рабочее время по номеру: <a href="tel:+7(962)690-05-22">+7 (962) 690-05-22</a>) и к вам подойдет представитель счетной комиссии с/за Вашим распечатанным <span *ngIf="locked">и заполненным вами</span> бюллетенем.</li>-->
<!--     </ul>-->
    <button class="full-width" style ="width: 100%;" mat-raised-button color="primary" (click)="showBulletin()"><span>Открыть <span
      *ngIf="locked">заполненное </span><span
      *ngIf="!locked">пустое </span>решение</span>
    </button>
    <br>
    <br>
    <br>



<!--    <h2 id="appendix">Приложения </h2>-->
<!--    <a *ngIf="backId" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment(backId, null)">&#60;&#60;назад</a>-->
<!--    <ol class="full-width"  style="width: calc(100% - 40px);">-->
<!--      <li><a href="https://cloud.mail.ru/public/KZKk%2FpS2mFu3tw" target="_blank">Отчёт о работе правления ТСН «Вернисаж» в 2019 году.</a></li>-->
<!--      <li><a href="https://cloud.mail.ru/public/9nsG%2F33LZantw3" target="_blank">План текущего ремонта на 2020г.</a></li>-->
<!--      <li><a href="https://cloud.mail.ru/public/931n%2FhSbHGG4qB" target="_blank">Смета доходов и расходов на 2020 год</a></li>-->
<!--      <li><a href="https://cloud.mail.ru/public/MqWJ%2Fwuun2ep5U" target="_blank">Исполнение текущего ремонта за 2019 год</a></li>-->
<!--      <li><a href="https://cloud.mail.ru/public/Ga4v%2FiNobonXAY" target="_blank">Исполнение сметы за 2019 год</a></li>-->
<!--      <li><a href="https://cloud.mail.ru/public/D58E/N8Te85DD1" target="_blank">ИЗВЕЩЕНИЕ</a></li>-->
<!--    </ol>-->
    <br>

    <br>
    <h2 id="results">Результаты голосования:</h2>
    <a *ngIf="backId" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment( backId, null)">&#60;&#60;Назад</a>&nbsp;&nbsp;<a *ngIf="locked === 'finish-result'" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment( 'i-am', 'results', 'center')">Показать меня в таблице &#62;&#62;</a>
    <div *ngIf="locked === 'finish-result'">

      <app-field-votes></app-field-votes>
    </div>
    <div *ngIf="locked !== 'finish-result'" style="
              background: yellow;
              padding: 3px 6px;
              border-radius: 5px;
              ">
      Скоро здесь будет таблица результатов голосования и ссылка на протокол. Они будут доступны после подведения итогов.
    </div>
    <br>
    <br>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-ellipsis">
            <span><mat-icon style="vertical-align: sub; color: #ffd400;">info</mat-icon>Подробная информация</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="">
          <a *ngIf="backId" [routerLink]="['/']" [queryParams]="route.snapshot.queryParams" (click)="scrollToFragment( backId, null)">&#60;&#60;назад</a>
          <ul>
            <li>Данная программа <b>не</b> представляет собой средство электронного голосования, а предназначена для повышения удобства голосования в очно-заочной форме в соответствии с п.3.2 ч.2 ст.44, ч.1 ст.47.1 ЖК РФ.</li>
            <li>Далее принимается что слова <b>«Бюллетень»</b> и <b>«Решение»</b> синонимы. </li>
            <li><b>«Ссылка»</b> - уникальный интернет-адрес, генерируемый для отдельного собственника, применяемый в программах просмотра содержимого интернета (браузерах).</li>
            <li>С помощью данной программы можно изучить документы, приложенные к повестке. Они находятся в разделе <b>«Приложения»</b>.</li>
            <li>Доступ к данной программе возможен только по уникальной ссылке, которую раздает правление. Ссылка имеет очень высокий уровень уникальности, то есть найти другого собственника методом подбора практически невозможно.
              <ul class="list-bird">
                <li>Не передавайте ссылку посторонним людям, иначе они увидят ваше Решение и ваши личные данные.</li>
                <li>Ссылку можно передать представителю по доверенности, что бы он заполнил и сдал Решение за вас.</li>
                <li>Не теряйте ссылку, по ней вы можете убедиться, что ваше Решение не изменено, а также увидите результаты голосования после публикации.</li>
                <li>При утере ссылки, вы все же можете обратиться в правление для ее повторного получения.</li>
              </ul>
            </li>
            <li>Программа генерирует Бюллетень с заполненными данными для собственника.</li>
            <li>Процедура использования данной программы возможна по сценариям:
              <ul class="list-bird">
                <li><b>Заполнить Решение вручную.</b> Для этого нажмите на кнопку «Открыть пустое Решение», сгенерированный бланк Бюллетеня в формате PDF откроется в другой вкладке браузера. Открытое Решение вы можете распечатать, после чего заполнить необходимые поля вручную, подписать и сдать. Распечатать можно и в правлении, но тогда ходить придется дважды.</li>
                <li><b>Ничего тут не делать.</b> Сходить в правление получить бланк решения заполнить и сдать. Вероятно, для этого придется сходить еще раз.</li>
                <li><b>Заполнить Решение с помощью программы</b>:
                  <ol>
                    <li>Первое что нужно сделать, выбрать кем вы являетесь по отношению к собственнику, представителем или самим собственником. Выберите, нажав на соответствующую кнопку.</li>
                    <li>Если вы представитель, заполните поле для данных представителя: фамилию имя и отчество полностью, а также дату и номер доверенности. Эти данные будут нужны для автоматического заполнения поля Бюллетеня.</li>
                    <li>Изучите вопросы в таблице и выберите ваше Решение из представленных: <span style="background: green; padding: 3px 6px; border-radius: 5px;">ЗА</span>, <span style="background: red; padding: 3px 6px; border-radius: 5px;">ПРОТИВ</span>, <span style="background: cornflowerblue; padding: 3px 6px; border-radius: 5px;">ВОЗДЕРЖАЛСЯ</span>. Вы можете поставить галочку «Показать вопросы подробно», для раскрытия официального текста Бюллетеня в таблице.</li>
                    <li>Нажмите кнопку «Завершить», тем самым вы утверждаете свой голос и блокируете его от редактирования. Это необходимо чтобы вы не смогли изменить свое решение после сдачи Бюллетеня счетной комиссии.</li>
                    <li>Программа попросит от вас подтверждения что вы хотите завершить голосование: нажмите «Точно завершить» если вы уверены в своем Решении или «Нет, еще подумаю», в противном случае.</li>
                    <li>После завершения голосования в электронном виде с помощью программы, Решение все равно необходимо распечатать самостоятельно или в правлении и подписать!</li>
                  </ol>
                </li>
              </ul>
            </li>
            <li>Вы можете распечатать Решение самостоятельно или в правлении, после чего поставить подписи и сдать.</li>
            <li>Способы сдачи Решения счетной комиссии:
              <ul class="list-bird">
                <li>Лично в помещении правления в холле четвертой парадной.</li>
                <li>Положив его в ящик у двери диспетческой (старайтесь положить его поглубже чтобы бумага не торчала) или отдать в руки диспетчеру.</li>
                <li>Прислать его почтой РФ, по адресу:<br/>  <u><b>188692, Россия, Ленинградская обл., Всеволожский р-н, г. Кудрово, ул. Пражская, д. 7, кв.319.</b></u></li>
                <li>Если вы на самоизоляции или у вас другие сложности с посещением правления, вы можете попросить принести и/или забрать бланк Решения у вас, для этого оставьте электронную заявку доступными средствами или позвоните в правление в рабочие часы по номеру: <b><a href="tel:+7(962)690-05-22">+7 (962) 690-05-22</a></b></li>
              </ul>
            </li>
            <li>Даже если вы не заполните голоса в программе самостоятельно, а сделаете это вручную на бумажном бланке вашего Решения, счетная комиссия занесет ваше Решение в систему, и позже вы увидите результат по этой же самой ссылке в электронном виде. Не теряйте ссылку. </li>
            <li>По Ссылке, кроме самого Решения, после завершения голосования и опубликования протокола, можно увидеть результаты голосования, сам протокол и доступные приложения к нему. Согласно Федеральному закону РФ «О персональных данных» от 27.07.2006г. № 152-ФЗ, приложения, содержащие персональные данные, не могут быть опубликованы. Для ознакомления с ними можно обратиться непосредственно в правление.</li>
            <li>Бюллетень имеет QR-код (квадрат с закодированными данными) в правом верхнем углу. В нем содержатся необходимые данные для Решения о собственнике, его представителе (при наличии), а также его Решение (ответы на вопросы).</li>
          </ul>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--    <h2 id="bigInfo"><span><mat-icon style="vertical-align: sub; color: #ffd400;">info</mat-icon>Подробная информация</span></h2>-->




    <div style="margin-bottom: 100vh;" ></div>
  </div>
</div>
