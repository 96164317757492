<!--suppress TypeScriptUnresolvedVariable -->
<div class="main-container"  addUploadDragDrop (onFileDropped)="uploadFile($event)" [dragDisable]="readOnly">
  <h1 *ngIf="title">{{title}}</h1>
      <div class="item-file"  *ngFor="let file of files" >
          <div class="text-ellipsis">
            <span ><a href="{{this.main.server + '/tenant/bulletin/appendix?view=true&o='+ownerGUId +'&fguid='+file.file.guid}}" target="_blank"><b style="vertical-align: super">{{file.file.name}}</b></a></span>
          </div>
          <div *ngIf="file.file.description" class="text-ellipsis">
            {{file.file.description}}
          </div>

      </div>
<br>

</div>
