<div>
  <table  style="table-layout: fixed; "  cellspacing="0" cellpadding="4" border="1">
    <thead >
    <tr style="height: 50px; background: white; " >
      <th  class="col-head-1 row-head">№<br>строки</th>
      <th  class="col-head-2 row-head">Собственник</th>
      <th  class="col-head-4 row-head">%</th>
      <th *ngFor="let col of fetchedColumns"  class="col-data row-head col-head-qwst" [matTooltip]="col[1]"><div class="rotate"> {{col[0]}}</div></th>
      <!--      <th style="width: 1px;"></th>-->
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of dataSource; let i = index" class="tenant-row" [class.other]="!!row.locked" [id]="!row.locked?'i-am':'id'+i">
      <th  class="row-head row-head-1">{{i+1}}</th>
      <th  class="row-head row-head-2">{{ row.fio }}</th>
      <th  class="row-head row-head-4">{{(row.square).toFixed(2)}} %</th>
      <td *ngFor="let val of row.answers.split('') " class="col-data " [className]="val" ></td>
    </tr>

    </tbody>
    <tfoot>
    <tr style="height: 50px; " class="f">
      <th  colspan="3" style="text-align: right">За</th>
      <th *ngFor="let col of votes_f"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="a" >
      <th  colspan="3" style="text-align: right">Против</th>
      <th *ngFor="let col of votes_a"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="r" >
      <th  colspan="3" style="text-align: right">Воздержался</th>
      <th *ngFor="let col of votes_r"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="d" >
      <th  colspan="3" style="text-align: right">Брак</th>
      <th *ngFor="let col of votes_d"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " >
      <th  colspan="3" style="text-align: right">Кворум</th>
      <th *ngFor="let col of votes_summ"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    </tfoot>
  </table>

</div>
