import {Component, OnInit} from '@angular/core';
import {MainService} from './main.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  get locked(): string {
    return this._locked;
  }

  set locked(value: string) {
    this._locked = value;
    console.log(this._locked);
  }
  // tslint:disable-next-line:max-line-length
  constructor(public http: HttpClient, public main: MainService, public route: ActivatedRoute, public snackBar: MatSnackBar, public viewportScroller: ViewportScroller) {

    // console.log(this.route);
    this.humanGuid = getParamValueQueryString('h');
    this.ossGuid = getParamValueQueryString('o');
    // this.route.paramMap.subscribe( params => {
    //   this.humanGuid = params.get('h');
    //   this.ossGuid = params.get('o');
    // });
  }
  get fillStatus(): string[] {
    const statusLine = [];
    this.isGood = true;
    if (this.isDelegate && !this.delegateValid) {
      statusLine.push('Вы представитель? Тогда введите данные о себе: ФИО полностью и номер доверенности. ');
      this.isGood = false;
    }
    if (this.bulletin && this.bulletin.qwsts && (!this.isDelegate || this.delegateEntered)) {
      const ind = this.bulletin.qwsts.findIndex(e => (e.type !== 'header' && (e.answer === null || e.answer === '' || e.answer === ' ')));
      const ind2 = this.bulletin.qwsts.findIndex(e => (e.type !== 'header' && (e.answer !== null && e.answer !== '' && e.answer !== ' ')));
      if (ind2 === -1){
        statusLine.push('Вы не ответили ни на один вопрос. Нажимайте цветные кнопки ЗА, ПРОТ или ВОЗД в разделе "Бюллетень. Вопросы и решения."');
        this.isGood = false;
        this.noAnswers = true;
      }else if (ind !== -1) {
        statusLine.push('Вы приняли решение не по всем вопросам. ');
        this.isGood = false;
        this.noAnswers = false;
      }
      else{
        this.noAnswers = false;
      }
    }
    if (this.isDelegate && !this.delegateEntered && this.delegateValid){
      statusLine.push('Если вы ввели данные о представителе, нажмите на кнопку "Поле представителя заполнено. Давайте дальше."');
      this.isGood = false;
    }
    return statusLine;
  }
  set fillStatus(value: string[]) {}
  get delegateValid() {
     return  this.doverka && this.doverka.length > 10;
  }
  private _locked = '';
  private fragment: string;
  noAnswers = true;
  title = 'FastOSS';
  humanGuid = '';
  ossGuid = '';
  bulletin: any;
  doverka = '';
  isDelegate = false;
  fio = 'Неизвестный';
  verbose: boolean;
  private voteStatus: any;
  isGood = false;
  loadStatus = 'loading';
  isBeginFinish: boolean;
  delegateSelected = false;

  delegateEntered = false;
  backId = null;

  ngOnInit(): void {
    // this.humanGuid = this.route.snapshot.paramMap.get('h');
    // this.ossGuid = this.route.snapshot.paramMap.get('o');
    // this.humanGuid = this.route.snapshot.queryParamMap.get('h');

    this.getBulleten();
    }

  // export getParamValueQueryString( paramName ) {
  //   const url = window.location.href;
  //   let paramValue;
  //   if (url.includes('?')) {
  //     const httpParams = new HttpParams({ fromString: url.split('?')[1] });
  //     paramValue = httpParams.get(paramName);
  //   }
  //   return paramValue;
  // }
  public scrollToFragment(elementId: string, fromId: string, blockMode?: ScrollLogicalPosition): void {
    const elmnt = document.getElementById(elementId);
    let opt: ScrollIntoViewOptions = {behavior: 'smooth', block: 'start', inline: 'nearest'};
    if (!blockMode) {
      opt = {behavior: 'smooth', block: blockMode, inline: 'nearest'};
    }
    elmnt.scrollIntoView(opt);
    this.backId = fromId;
    // this.viewportScroller.scrollToAnchor(elementId);
  }

  getBulleten( ){
    this.http.get<any>(this.main.server + '/tenant/bulletin?' +
      'h=' + this.humanGuid +
      '&o=' + this.ossGuid).subscribe(
      data => {
        this.bulletin = data;
        this.locked = data.lock;
        this.fio = data.human.fio;
        document.title = `Электронный бюллетень: ${this.fio}`;
        if (data.doverka)
        {
          this.isDelegate = true;
          this.doverka = data.doverka;
        }

        this.doverka = data.doverka;

        this.bulletin.qwsts_dict = {};
        let ind = 0;
        for ( const q of this.bulletin.qwsts){
          if (q.answer === 'd') {
            q.answer = '';
          }
          this.bulletin.qwsts_dict[q.id] = ind;
          ind++;
        }
        this.loadStatus = 'exists';
      },
      err => {
        if (err.status === 404) {
          this.loadStatus = 'not-found';
        }
      }
    );
  }

  saveDoverka() {
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      doverka: this.isDelegate ? this.doverka : '',
    };
    this.http.put(this.main.server + '/tenant/bulletin/set-doverka', body).subscribe(
      data => {
        // this.snackBar.open('Данные о представителе собственника сохранены', 'Ок', {
        //   verticalPosition: 'top',
        //   panelClass: 'ok-panel',
        //   duration: 5000,
        // });
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        } else {
          this.snackBar.open('Не удалось сохранить данные о представителе собственника ', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 100000,
          });
        }
      });
  }

  setAnswer(questionId, answer){
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      question_id: questionId,
      answer,
    };
    this.http.put<any>(this.main.server + '/tenant/bulletin/set-answer', body).subscribe(
      data => {
        // this.snackBar.open(answer === '' ? 'Голос отменен' : 'Голос сохранен', 'Ок', {
        //   verticalPosition: 'top',
        //   panelClass: 'ok-panel',
        //   duration: 5000,
        // });
        answer = data.answer;
        // console.log(this.bulletin);
        const qwst = this.bulletin.qwsts[this.bulletin.qwsts_dict[answer.question]];
        qwst.answer = answer.answer;

        const ind = this.bulletin.qwsts.findIndex( e => (e.type !== 'header' && ( e.answer === null || e.answer === '')));
        this.isGood = ind === -1 && (!this.isDelegate || this.doverka.length > 10);
        if (this.isGood && this.bulletin.vote_status.key !== 'fill'){
          this.setStatus( 'fill', 'Бюллетень заполнен');
        }
        else if (!this.isGood && this.bulletin.vote_status.key === 'fill'){
          this.setStatus('take', 'Бюллетень НЕ заполнен');
        }
        // let ind = this.bulletin.qwsts.findIndex(e => e.id === answer.question);
        // console.log(ind);
        // let qwst = this.bulletin.qwsts[ind];
        // qwst.answer = answer.answer;
        // qwst.user = answer.user;
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        }
      }
    );
  }

  setStatus(statusKey: string, description: string){
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      statusKey,
      description,
    };
    this.http.put<any>(this.main.server + '/tenant/bulletin/set-status', body).subscribe(
      data => {
        this.voteStatus = data.new_status;
        if (this.voteStatus === 'fill') {
          this.snackBar.open('Решение заполнено', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 3000,
          });
        }
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        }
      }
    );
  }

  showBulletin() {
      window.open(this.main.server + `/tenant/bulletin/get-pdf?view=true&h=${this.humanGuid}&o=${this.ossGuid}`, '_blank');
  }



  delegateChanged() {
    this.saveDoverka();
  }

  finish() {

  }

  lockBulletin() {
    this._lockBulletin();
  }


  _lockBulletin() {
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
    };
    this.http.put<any>(this.main.server + '/tenant/bulletin/lock-vote', body).subscribe(
      data => {
        this.locked = data.lock;
        this.snackBar.open('Бюллетень закрыт и заблокирован', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 3000,
        });
      },
      err => {
        this.snackBar.open('Не удалось закрыть бюллетень', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 3000,
        });
      }
    );
  }

  collapseItemPanel($event: void) {
  }
}


export const getParamValueQueryString = ( paramName ) => {
  const url = window.location.href;
  let paramValue;
  if (url.includes('?')) {
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    paramValue = httpParams.get(paramName);
  }
  return paramValue;
};
