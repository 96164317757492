import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor() { }


  public debug_server = 'http://localhost:8000';
  public battle_server = 'https://api.vernissage.life';
  private debugServer = 'http://192.168.0.106:8000';
  private debubLocalServer = 'http://localhost:8000';
  IsMenuOpened: boolean = false;
  showPhones = false;
  selectedStatusesSection = [];


  get server() {
    // return "https://api.vernissage.life"
    if (document.location.hostname === 'localhost' ) {
      return this.debubLocalServer;
    } else if (document.location.hostname === '192.168.0.106') {
      return this.debugServer;
    } else if (document.location.host == 'localhost:4200' || document.location.host == 'localhost:4300' ) {
      return this.debug_server;
    } else {
      return 'https://api.vernissage.life';
    }
  }
}
