/* tslint:disable:variable-name */
import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {getParamValueQueryString} from '../app.component';

@Component({
  selector: 'app-field-votes',
  templateUrl: './field-votes.component.html',
  styleUrls: ['./field-votes.component.sass']
})
export class FieldVotesComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService) {
    this.humanGuid = getParamValueQueryString('h');
    this.ossGuid = getParamValueQueryString('o');
  }

  humanGuid = '';
  ossGuid = '';
  fetchedColumns = [];
  fetchedRows = [];
  colnum = 0;
  baseColumns =  ['owner'];
  displayedColumns =
    ['number', 'owner', 'square', 'volume'];
  dataSource: AnswerRow[] = [];
  oss_id = 0;
  home_square = 1;
  total_votes = 1;
  votes_f = [];
  votes_a = [];
  votes_r = [];
  votes_d = [];
  votes_summ = [];
  ngOnInit() {
    this.getRows();
  }

  getRows(){

    this.http.get<any>(this.main.server +
      '/tenant/field-votes?' +
      'h=' + this.humanGuid +
      '&o=' + this.ossGuid).subscribe(
      data => {
        this.fetchedColumns = data.questions;
        this.dataSource = data.answers;
        this.votes_f = data.votes_f;
        this.votes_a = data.votes_a;
        this.votes_r = data.votes_r;
        this.votes_d = data.votes_d;
        this.votes_summ = data.votes_summ;
        this.displayedColumns = this.baseColumns.concat(this.fetchedColumns);
        this.home_square = data.home_square;
        this.total_votes = data.total_votes;
        console.log(this.dataSource);
      },
      err => {

      }
    );

  }

}

export interface AnswerRow {
  id: number;
  fio: string;
  square: number;
  answers: string;
  locked: string;
}
