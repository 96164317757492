import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { FieldVotesComponent } from './field-votes/field-votes.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FilesComponent} from './modules/files/files.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AddUploadFileDirective} from './directives/upload-file/add-upload-file.directive';


@NgModule({
  declarations: [
    AppComponent,
    FieldVotesComponent,
    FilesComponent,
    AddUploadFileDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule, MatSnackBarModule,
    HttpClientModule,
    RouterModule.forRoot([
        {path: '**', component: AppComponent}
      ], {anchorScrolling: 'enabled'}
    ),
    MatCheckboxModule, MatIconModule, MatButtonModule, MatTooltipModule, MatExpansionModule, PdfViewerModule, MatProgressBarModule, MatSlideToggleModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
